export const config = (props) => {
  const firstNameField = {
    hasTopLabel: true,
    type: 'INPUT',
    id: 'first_name',
    label: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome'
    },
    placeholder: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome'
    },
    style: {
      display: 'grid',
      'grid-column': '1/2'
    }
  }
  const lastNameField = {
    type: 'INPUT',
    id: 'last_name',
    label: {
      es: 'Apellido',
      en: 'Last name',
      pt: 'Sobrenome'
    },
    placeholder: {
      es: 'Apellido',
      en: 'Last name',
      pt: 'Sobrenome'
    },
    style: {
      display: 'grid',
      'grid-column': '2/3',
      'align-self': 'end'
    }
  }
  const form = {
    key: 'ONBOARDING_EXPRESS',
    title: {
      es: 'Hablemos de ti',
      pt: 'Vamos falar sobre você',
      en: "Let's Talk About You"
    },
    description: {
      es: 'Tu perfil es tu espacio personal. Cada detalle que compartes nos permite conocerte y ofrecerte una experiencia más personalizada.',
      pt: 'Seu perfil é seu espaço pessoal. Cada detalhe que você compartilha nos permite conhecê-lo e oferecer uma experiência mais personalizada.',
      en: 'Your profile is your personal space. Every detail you share with us allows us to get to know you and offer a more personalized experience.'
    },
    layout: {
      rows: 'repeat(4, min-content)',
      columns: 'repeat(2, 1fr)'
    },
    fields: [
      {
        tooltip: {
          es: 'Tu fecha de nacimiento es especial para nosotros. Nos ayuda a conocerte mejor. Además, ¡nos da la oportunidad de celebrar contigo en tu día especial!',
          en: 'Your date of birth is special to us. It helps us get to know you better. Plus, it gives us the opportunity to celebrate with you on your special day!',
          pt: 'Sua data de nascimento é especial para nós. Isso nos ajuda a conhecê-lo melhor. Além disso, nos dá a oportunidade de comemorar com você em seu dia especial!'
        },
        hasTopLabel: true,
        label: {
          es: 'Fecha de nacimiento',
          en: 'Birthday',
          pt: 'Data de nascimento'
        },
        placeholder: {
          day: {
            es: 'Día',
            en: 'Day',
            pt: 'Dia'
          },
          month: {
            es: 'Mes',
            en: 'Month',
            pt: 'Mês'
          },
          year: {
            es: 'Año',
            en: 'Year',
            pt: 'Ano'
          }
        },
        type: 'BIRTHDAY',
        id: 'birthday',
        style: {
          'grid-column': '1/3'
        }
      },
      {
        tooltip: {
          es: 'Usamos tu número de teléfono solo para notificarte sobre temas relacionados con tu atención y bienestar',
          en: 'We use your phone number only to notify you about matters related to your care and well-being',
          pt: 'Usamos seu número de telefone apenas para notificá-lo sobre assuntos relacionados ao seu cuidado e bem-estar'
        },
        hasTopLabel: true,
        label: {
          es: 'Número de teléfono',
          en: 'Phone number',
          pt: 'Número de telefone'
        },
        type: 'PHONE',
        id: 'phone',
        style: {
          'grid-column': '1/3',
          'border-top': '1px solid var(--gray-10)'
        }
      },
      {
        tooltip: {
          es: 'Compartir tu género con nosotros permitirá que tu terapeuta te brinde una atención más personalizada.',
          en: 'Sharing your gender with us will allow your therapist to provide you with more personalized care.',
          pt: 'Compartilhar seu gênero conosco permitirá que seu terapeuta forneça um atendimento mais personalizado.'
        },
        hasTopLabel: true,
        label: {
          es: 'Género',
          en: 'Gender',
          pt: 'Gênero'
        },
        type: 'SELECT_OPENED',
        id: 'gender',
        options: [
          {
            value: 'FEMALE',
            name: {
              es: 'Mujer',
              en: 'Female',
              pt: 'Mulher'
            }
          },
          {
            value: 'MALE',
            name: {
              es: 'Hombre',
              en: 'Male',
              pt: 'Homem'
            }
          },
          {
            value: 'NON_BINARY',
            name: {
              es: 'No binario',
              en: 'Non binary',
              pt: 'Não binário'
            }
          },
          {
            value: 'NO_SPECIFY',
            name: {
              es: 'Prefiero no especificar',
              en: 'I prefer not to specify',
              pt: 'Prefiro não especificar'
            }
          }
        ],
        style: {
          'grid-column': '1/3',
          'border-top': '1px solid var(--gray-10)'
        }
      }
    ]
  }

  if (props.includeName) {
    const birthdayFieldIndex = form.fields.findIndex((o) => o.id === 'birthday')
    form.fields[birthdayFieldIndex].style = {
      ...form.fields[birthdayFieldIndex].style,
      'border-top': '1px solid var(--gray-10)'
    }
    form.fields = [firstNameField, lastNameField, ...form.fields]
  }
  return [form]
}
