<template>
  <div>
    <div class="segment padding-x-sm" v-if="config && !loading && !showFlow">
      <div class="segment-content">
        <FormContainer
          @values="updateUser"
          @finish-form="isDone"
          @config-with-values="changeConfig"
          :config="config"
          :lang="lang"
          :step-default="stepDefault"
          :next-step="nextStep"
          :has-back-button="true"
          :has-navigation="false"
          custom-class="padding-x-xs"
        />
      </div>
    </div>

    <FlowResolver
      :flow="flow"
      :router="$router"
      :route="$route"
      :lang="lang"
      :callback="updateSurveyAnswers"
      @handle-show-loader="handleLoader($event)"
      v-if="showFlow"
    />
  </div>
</template>
<script>
import {
  FormContainer,
  Onboarding,
  Promos,
  Flow,
  FlowResolver,
  RoleType
} from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
import { formTypes, OnBoardingSteps } from '../configuration/configuration'
import { config } from '@/views/onboarding/configuration/simpleObConfig'
import { OnboardingEvent } from '@/types/events'

export default {
  name: 'ObQuestionnaire',
  components: {
    FormContainer,
    FlowResolver
  },
  data () {
    return {
      loading: false,
      config: null,
      stepDefault: {
        has: false,
        position: 0
      },
      nextStep: false,
      beforeStep: false,
      showFlow: false,
      flow: null,
      hasBenefit: false,
      variationId: 0
    }
  },
  created () {
    if (localStorage.getItem('ShowObSlider')) {
      this.$router.push({ name: 'SlideOnboarding' })
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      await this.checkBenefits()
      localStorage.setItem('ShowObSlider', JSON.stringify(true))
      await this.getStep()
    },
    async checkBenefits () {
      const benefit = await Promos.Benefits.getBenefits().catch()
      this.hasBenefit = benefit.benefits && benefit.benefits.length > 0
    },
    async getStep () {
      this.loading = true
      this.config = config({
        includeName: !this.user.first_name || !this.user.last_name
      })

      this.flow = await Flow.get('ON_BOARDING')

      // open modal

      if (!localStorage.getItem('OnboardingWelcomeDialog')) {
        this.$globalEvent.$emit('modal/sign-up-success', {
          showDialog: {
            signUpSuccess: {
              open: true,
              close: false,
              flowkey: this.flow.key
            }
          }
        })
      }

      this.getDataFromUser()
      this.loading = false
    },
    getDataFromUser () {
      for (const item of this.config) {
        let response = {}
        for (const field of item.fields) {
          let values = null

          switch (field.type) {
            case formTypes.BIRTHDAY:
              if (this.user?.birthday) {
                values = this.$moment(this.user.birthday).toDate()
              }
              break
            case formTypes.GENDER:
              if (this.user?.birthday) {
                values = this.user.gender
              }
              break
            case formTypes.PHONE:
              values = {
                phone: this.user?.phone || '',
                dialCode: this.user?.dial_code
                  ? this.user.dial_code.replace('+', '')
                  : '',
                country: this.user?.dial_country_code || ''
              }
              break

            default:
              break
          }

          response = {
            ...response,
            [field.id]: { values }
          }
        }
        item.response = response
      }
    },
    changeConfig (value) {
      this.config = [...value]
    },
    updateUser (val) {
      const response = val.values
      const step = OnBoardingSteps.ONBOARDING_EXPRESS

      // data
      const body = {
        first_name: this.user.first_name || response.first_name.values,
        last_name: this.user.last_name || response.last_name.values,
        birthday: response.birthday.values,
        gender: response.gender.values.value,
        dial_code: response.phone.values.dialCode,
        phone: response.phone.values.phone,
        dial_country_code: response.phone.values.country
      }

      // data events
      const bodyEvent = {
        user: { ...this.user },
        plaftorm: this.$store.getters.platform,
        version: this.$version,
        first_name: body.first_name,
        last_name: body.last_name,
        birthday: body.birthday,
        dial_code: body.dial_code,
        phone_number: body.phone,
        gender: body.gender,
        dial_country_code: response.phone.values.country
      }

      this.$segment.identifyAppUser({
        first_name: body.first_name,
        last_name: body.last_name,
        birthday: body.birthday,
        dial_code: body.dial_code,
        phone: body.phone,
        gender: body.gender,
        id: this.user.id,
        email: this.user.email
      })

      if (this.user.first_name && this.user.first_name) {
        OnboardingEvent.completeProfileClick(bodyEvent)
      } else {
        OnboardingEvent.completeProfileNameClick(bodyEvent)
      }
      this.updateOnboarding(body, step, bodyEvent)
    },
    updateSurveyAnswers (data, noRedirect) {
      return new Promise((resolve, reject) => {
        Flow.post(data)
          .then(() => {
            this.handleFinishQuestionnaire(noRedirect)
            localStorage.removeItem('OnboardingWelcomeDialog')
            localStorage.removeItem('ShowObSlider')
          })
          .catch((error) => {
            data = {
              ...data,
              error_type: error
            }
            OnboardingEvent.onboadingFailed(data)
            reject(error)
          })
      })
    },
    updateOnboarding (body, bodyEvent) {
      let setAccountCreatedState = false
      if (this.role === RoleType.USER_HIDE_PRICES) {
        setAccountCreatedState = true
      } else if (this.$route.query.next) {
        setAccountCreatedState = true
      }
      Onboarding.updateForm(body, setAccountCreatedState)
        .then(() => {
          this.$store.dispatch('auth/fetchUser').then(() => {
            this.nextStep = true
          })
        })
        .catch((error) => {
          bodyEvent = {
            ...bodyEvent,
            error_type: error
          }
          OnboardingEvent.onboadingFailed(bodyEvent)
        })
        .finally(() => (this.nextStep = false))
    },
    async handleFinishQuestionnaire (noRedirect) {
      await this.$store.dispatch('auth/fetchUser').then()

      if (!noRedirect) {
        return this.$router.push({
          path: this.showFlow ? this.flow.exit_path : '/matching'
        })
      }
    },
    isDone () {
      if (this.$route.query.next) {
        setTimeout(() => {
          return this.$router.push({
            path: this.$route.query.next,
            query: this.$route.query
          })
        }, 1000)
      }

      if (this.role === RoleType.USER_HIDE_PRICES) {
        this.handleFinishQuestionnaire()
      } else {
        setTimeout(() => {
          this.showFlow = true
        }, 1000)
      }
    },
    handleLoader (option) {
      if (option) {
        this.$loader.handleShowLoader({
          lang: this.$store.getters.language.lang,
          type: 'degrade',
          key: 'MATCHING'
        })
      } else {
        this.$loader.handleRemoveLoader()
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      role: 'auth/role'
    }),
    lang () {
      return String(this.$store.getters.language.lang)
    }
  }
}
</script>
<style lang="sass" scoped>
::v-deep #inputText
  padding: 8px 12px !important
  border-radius: 8px !important
  transition: all .2s !important
</style>
