<template>
  <div>
    <OnboardingQuestionnaireV2 />
  </div>
</template>

<script>
import OnboardingQuestionnaireV2 from '@/views/onboarding/v2/onboarding-questionnaire'
import { mapGetters } from 'vuex'
import { activeStatus } from '@/types/state'

export default {
  name: 'ObContainer',
  components: { OnboardingQuestionnaireV2 },
  mounted () {
    if (activeStatus.includes(this.user.status)) {
      this.$router.replace({ name: 'Home' })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
}
</script>
